import { FC } from 'react'
import { Row, Col, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { UseMutateFunction } from '@tanstack/react-query'
import { RuleResponse, RuleSet } from '@signifyd/http'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import { useGetRulesByRuleSet } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { SPLIT_SCREEN_STEP } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { useIsThirdGen } from '@signifyd/components'
import RuleSetDnD from '../RuleSetDnD'
import SimulationContainer from '../SimulationContainer'
import styles from './PublishWithSimulatorPageContent.less'
import RuleSetDnDThirdGen from '../RuleSetDnD/RuleSetDnDThirdGen'

interface Props {
  currentStep?: SPLIT_SCREEN_STEP
  isEditing: boolean
  mutate: UseMutateFunction<
    RuleSet,
    unknown,
    {
      ruleSet: RuleSet
      publishedRules: Array<RuleResponse>
      otherRules: Array<RuleResponse>
    },
    unknown
  >
}

export const RULEDND_STEPS_GRID_SPAN = 8
export const SIMULATIONCONTAINER_STEPS_GRID_SPAN = 16

const PublishWithSimulatorPageContent: FC<Props> = ({
  currentStep = SPLIT_SCREEN_STEP.STEP_ONE,
  isEditing,
  mutate,
}) => {
  const { t } = useTranslation()
  const [{ ruleSetId }, setQueryParams] = useQueryParams(PublishPageQueryParams)

  const { data: ruleSet } = useGetRuleSet(ruleSetId)
  const { otherRules, publishedRules } = useGetRulesByRuleSet()
  const isThirdGen = useIsThirdGen()

  if (currentStep === SPLIT_SCREEN_STEP.STEP_ONE) {
    return (
      <div
        data-test-id="publishPageStep1"
        data-analytics-id="publish-page-step-1"
      >
        {isThirdGen ? (
          <RuleSetDnDThirdGen
            ruleSet={ruleSet}
            publishedRules={publishedRules}
            otherRules={otherRules}
            mutate={mutate}
          />
        ) : (
          <RuleSetDnD
            ruleSet={ruleSet}
            publishedRules={publishedRules}
            otherRules={otherRules}
            mutate={mutate}
          />
        )}
      </div>
    )
  }

  return (
    <Row
      className={styles.container}
      data-test-id="publishPageStep2"
      data-analytics-id="publish-page-step-1"
    >
      <Tooltip
        title={isEditing && t('publishWithSimulatorPage.disabledTooltip')}
      >
        <Col
          className={styles.ruleColumn}
          xs={0}
          xxl={RULEDND_STEPS_GRID_SPAN}
          onClick={() => {
            if (!isEditing) {
              setQueryParams({
                step: SPLIT_SCREEN_STEP.STEP_ONE,
              })
            }
          }}
          data-test-id="editRuleColumn"
          data-analytics-id="edit-rule-column"
        >
          <div
            className={styles.ruleColumnChild}
            style={isEditing ? { cursor: 'not-allowed' } : undefined}
          >
            <div>
              {isThirdGen ? (
                <RuleSetDnDThirdGen
                  ruleSet={ruleSet}
                  publishedRules={publishedRules}
                  otherRules={otherRules}
                  mutate={mutate}
                />
              ) : (
                <RuleSetDnD
                  ruleSet={ruleSet}
                  publishedRules={publishedRules}
                  otherRules={otherRules}
                  mutate={mutate}
                />
              )}
            </div>
            <div className={styles.screen} />
          </div>
        </Col>
      </Tooltip>
      <Col
        xs={24}
        xxl={SIMULATIONCONTAINER_STEPS_GRID_SPAN}
        className={styles.simulationColumn}
        data-test-id="runSimulationColumn"
        data-analytics-id="run-simulation-column"
      >
        <SimulationContainer />
      </Col>
    </Row>
  )
}

export default PublishWithSimulatorPageContent
