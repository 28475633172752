import { FC, useEffect } from 'react'
import { Row, Col, Breadcrumb, Typography, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Layout,
  Space,
  PageSpinnerThirdGen,
  TextThirdGen,
} from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import TestTeamBanner from 'core/components/TestTeamBanner'
import useLoadPolicyForValidation from 'core/hooks/useLoadAndValidatePolicy'
import { getPredictionListParamsForTeam } from 'core/http'
import { getDefaultDashboardUrl } from 'pages/DashboardPage/DashboardPage.utils'
import RuleValidationResult from 'pages/PolicySummaryPage/components/RuleValidationResult'
import { useStoreState, useStoreActions } from 'stores'
import {
  isConditionTreeValid,
  isPredicateUsingPredictionListFeatures,
} from 'stores/conditionTree'
import useTimeframe from 'core/hooks/useTimeframe'
import RuleSummaryCardThirdGen from 'core/components/RuleSummaryCard/RuleSummaryCardThirdGen'
import PageWrapper from 'core/containers/PageWrapper/PageWrapper'
import Header from '@signifyd/components/src/components/Layout/_Header'
import { spacingXL } from '@signifyd/ant'
import useConditionTreeFromPolicy from 'core/hooks/useConditionTreeFromPolicy/useConditionTreeFromPolicy'
import useUserPermissions from 'core/hooks/useUserPermissions'
import PolicyReports from './components/PolicyReports/PolicyReports'
import SummaryHeaderActions from './components/SummaryHeaderActions/SummaryHeaderActions'

const { Content } = Layout

const PolicySummaryPageThirdGen: FC = () => {
  const { ruleId } = useParams()

  const { policy, predictionListMap, policyFeaturesByName } = useStoreState(
    (state) => state.ruleModel
  )

  const conditionTree = useConditionTreeFromPolicy({
    policy,
    policyFeaturesByName,
  })

  const { userCanEdit } = useUserPermissions(policy?.teamId)

  const isPolicyValid =
    !!policy?.ruleOutcome?.ruleRecommendedAction &&
    isConditionTreeValid(conditionTree)

  const { listPredictionLists } = useStoreActions(
    (actions) => actions.ruleModel
  )

  const { t } = useTranslation()
  const { timeframe, updateTimeframe } = useTimeframe()

  useLoadPolicyForValidation(ruleId, false, false)

  useEffect(() => {
    if (
      !predictionListMap &&
      policy &&
      // only get lists if policy is using list id feature
      isPredicateUsingPredictionListFeatures(policy.predicate)
    ) {
      const params = getPredictionListParamsForTeam(policy.teamId)

      listPredictionLists(params)
    }
  }, [predictionListMap, listPredictionLists, policy])

  if (!policy || !conditionTree) {
    return (
      <>
        <PageWrapper teamId={policy?.teamId}>
          <PageSpinnerThirdGen />
        </PageWrapper>
      </>
    )
  }

  return (
    <PageWrapper teamId={policy?.teamId}>
      <TestTeamBanner
        teamId={policy.teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <Header
        title={
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <TextThirdGen size="sm">
                    {t('common.checkpoint.label')}:{' '}
                    {t(`common.checkpoint.${policy.checkpoint}`)}
                  </TextThirdGen>
                ),
              },
              {
                title: <TextThirdGen size="sm">{policy.name}</TextThirdGen>,
                href: `${getDefaultDashboardUrl(policy, timeframe)}`,
              },
            ]}
          />
        }
        theme="light"
        marginBottom="none"
      />
      <Header
        title={
          <>
            <Flex gap={spacingXL}>
              <Typography.Title level={1}>{policy.name}</Typography.Title>
              {userCanEdit && <SummaryHeaderActions policy={policy} />}
            </Flex>
            {policy?.description && (
              <TextThirdGen weight="normal">{policy.description}</TextThirdGen>
            )}
          </>
        }
        theme="light"
        marginBottom="lg"
      />
      <Content>
        <Row gutter={32}>
          <Col span={6}>
            <RuleSummaryCardThirdGen
              isRuleValid={isPolicyValid}
              conditionTree={conditionTree}
              recommendedAction={policy.ruleOutcome?.ruleRecommendedAction}
              rankInActiveRuleSet={policy.rankInActiveRuleSet}
            />
          </Col>
          <Col span={18}>
            {policy.checkpoint !== CHECKPOINT.LOGIN && (
              <>
                <PolicyReports
                  policyId={policy.ruleId}
                  checkpoint={policy.checkpoint}
                  timeframe={timeframe}
                  setTimeframe={updateTimeframe}
                />
                <Space size="lg" />
              </>
            )}
            <RuleValidationResult
              data-test-id="validationResultMatching"
              title={t('ruleSummaryPage.ruleValidationResult.matching.title')}
              validationResults={policy.matchingPolicyMatchIds}
              checkpoint={policy.checkpoint}
            />
            <Space size="lg" />
            <RuleValidationResult
              data-test-id="validationResultNonMatching"
              title={t(
                'ruleSummaryPage.ruleValidationResult.nonMatching.title'
              )}
              validationResults={policy.nonMatchingPolicyMatchIds}
              checkpoint={policy.checkpoint}
            />
            <Space size="sm" />
          </Col>
        </Row>
      </Content>
    </PageWrapper>
  )
}

export default PolicySummaryPageThirdGen
