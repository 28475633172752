import { ForwardRefRenderFunction, ReactNode, forwardRef } from 'react'
import cx from 'classnames'
import { Flex } from 'antd'
import styles from './RuleSetDnDDroppableRow.less'

interface Props {
  children?: ReactNode
  className?: string
  [props: string]: any
}

const renderRow: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { className, children, ...rest }: Props,
  ref
) => (
  <Flex
    align="center"
    {...rest}
    ref={ref}
    className={cx([styles.row, className])}
  >
    {children}
  </Flex>
)

const RuleSetDnDDroppableRow = forwardRef(renderRow)

export default RuleSetDnDDroppableRow
