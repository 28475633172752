import { FC } from 'react'
import { Menu, Dropdown, MenuItemProps } from 'antd'
import { RuleResponse } from '@signifyd/http'
import { TableDropdownIcon } from 'core/components/Icons'
import useTimeframe from 'core/hooks/useTimeframe'
import useUserPermissions from 'core/hooks/useUserPermissions'
import { OnClickRuleMenuItem } from './RuleDropdown.types'
import styles from './RuleDropdown.less'
import getMenuItems from './RuleDropdown.config'

interface Props {
  rule: RuleResponse
  onClickEditRuleDetails: OnClickRuleMenuItem
  onClickManageSchedule: OnClickRuleMenuItem
  onClickDeletePolicy: OnClickRuleMenuItem
}

const RuleDropdown: FC<Props> = ({
  rule,
  onClickEditRuleDetails,
  onClickManageSchedule,
  onClickDeletePolicy,
}) => {
  const { timeframe } = useTimeframe()
  const { userCanEdit } = useUserPermissions()
  const handleMenuClick: MenuItemProps['onClick'] = ({ domEvent }): void => {
    domEvent.stopPropagation()
  }
  const menuItems = getMenuItems(
    rule,
    onClickEditRuleDetails,
    onClickManageSchedule,
    onClickDeletePolicy,
    timeframe,
    userCanEdit
  )
  const menu = <Menu onClick={handleMenuClick}>{menuItems}</Menu>

  return (
    <Dropdown placement="bottomRight" trigger={['click']} overlay={menu}>
      <div
        data-test-id={`policyDropdownTrigger-${rule.ruleId}`}
        className={styles.iconWrapper}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
        }}
      >
        <TableDropdownIcon />
      </div>
    </Dropdown>
  )
}

export default RuleDropdown
