import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { PREDICTION_LIST_TYPE, PredictionListEntry } from '@signifyd/http'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import { useDeletePredictionListEntries } from 'core/queries/predictionListEntries/useDeletePredictionListEntries'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import { getStoreFiltersFromSearchParams } from 'pages/ListDetailsPage/ListDetailsPage.utils'
import getColumns from './ListEntriesTable.config'

const useListEntriesColumnConfig = (
  predictionListId: number,
  predictionListType: PREDICTION_LIST_TYPE,
  userCanEdit: boolean
): Array<ColumnProps<PredictionListEntry>> => {
  const { t } = useTranslation()

  const userTimeZone = useUserTimeZone()

  const [query] = useListDetailsPageQueryParams()
  const filters = getStoreFiltersFromSearchParams(query)
  const deletePredictionListEntries = useDeletePredictionListEntries()

  const columnConfig = getColumns({
    title: {
      value: t(
        `listDetailsPage.listEntriesTable.valueColTitleByListType.${predictionListType}`
      ),
      createdAt: t('listDetailsPage.listEntriesTable.columnTitle.createdAt'),
      createdByName: t(
        'listDetailsPage.listEntriesTable.columnTitle.createdByName'
      ),
    },
    orderBy: filters?.orderBy ?? null,
    ascending: filters?.ascending ?? null,
    onDeleteEntry: (entryId) => {
      deletePredictionListEntries.mutate({
        predictionListId,
        ids: [entryId],
      })
    },
    userTimeZone,
    userCanEdit,
  })

  return columnConfig
}

export default useListEntriesColumnConfig
