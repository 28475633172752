import { FC, useState } from 'react'
import { Button, Flex } from 'antd'
import { Text } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import PredictionListTypeTag from 'core/containers/PredictionListTypeTag'
import EditListDetailsModal from 'pages/ListDetailsPage/containers/EditListDetailsModal'
import { HTTPStatus } from 'core/http'
import useUpdatePredictionList from 'core/hooks/useUpdatePredictionList'
import styles from './ListDetailsPageTitle.less'

interface Props {
  predictionList: PredictionList
  userCanEdit?: boolean
}

const ListDetailsPageTitle: FC<Props> = ({ predictionList, userCanEdit }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const { t } = useTranslation()

  const {
    mutateAsync: updatePredictionList,
    isLoading,
    isSuccess,
    error,
  } = useUpdatePredictionList()

  const updatePredictionListHTTPStatus: HTTPStatus = {
    pending: isLoading,
    success: isSuccess,
    error: error?.message,
  }

  return (
    <Flex align="center" gap={16}>
      <PredictionListTypeTag type={predictionList.type} />
      <Text weight="semibold" className={styles.title}>
        {predictionList.name}
      </Text>
      {userCanEdit && (
        <Button
          type="link"
          className={styles.editBtn}
          onClick={() => setModalVisible(true)}
        >
          {t('listCommon.editListDetailsModal.title')}
        </Button>
      )}
      <EditListDetailsModal
        httpStatus={updatePredictionListHTTPStatus}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={async (listDetails) => {
          await updatePredictionList({
            listId: predictionList.id,
            payload: listDetails,
          })

          setModalVisible(false)
        }}
        currentName={predictionList.name}
        currentDescription={predictionList.description || undefined}
        usedInPolicy={predictionList.ruleIds.length > 0}
      />
    </Flex>
  )
}

export default ListDetailsPageTitle
