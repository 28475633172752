import { FC } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import { backOrDefault } from 'core/utils/router'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './ListDetailsPageHeader.less'

interface Props {
  predictionList: PredictionList
}

const ListDetailsPageHeader: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const userTimeZone = useUserTimeZone()

  const [query] = useDashboardPageFilters()

  return (
    <PageHeader
      className={styles.pageHeader}
      backIcon={<ArrowLeftOutlined className={styles.backIcon} />}
      title=""
      subTitle={
        <span
          onClick={() =>
            backOrDefault(
              location?.state,
              `/lists?teamId=${query.teamId}`,
              navigate
            )
          }
          className={styles.subTitle}
        >
          {t('listDetailsPage.backBtn')}
        </span>
      }
      extra={
        <div className={styles.extra}>
          <Text block size="xs" type="secondary">
            {t('listDetailsPage.createdBy')} {predictionList.createdByName}{' '}
            <FormatDate
              UTCTimestamp={predictionList.createdAt}
              timeZone={userTimeZone}
            />
            <Divider type="vertical" />
            {userTimeZone}
          </Text>
        </div>
      }
      onBack={() =>
        backOrDefault(
          location?.state,
          `/lists?teamId=${query.teamId}`,
          navigate
        )
      }
    />
  )
}

export default ListDetailsPageHeader
