import useUserTeams from 'core/hooks/useUserTeams'
import { useDashboardPageFilters } from './useDashboardPageFilters'

const useTeamId = (): number | undefined => {
  const [{ teamId }] = useDashboardPageFilters()
  const { liveTeams } = useUserTeams()

  if (teamId) {
    return teamId
  }

  if (!liveTeams?.length) {
    return undefined
  }

  return liveTeams[0].teamId
}

export default useTeamId
