import { FC } from 'react'
import { colorCerulean, colorAtlantic } from '@signifyd/colors'
import {
  NavigationBar,
  SlidersDuoTone,
  SquareListDuoTone,
  useUpdateUserState,
  useUserContext,
  i18nInstance,
} from '@signifyd/components'
import {
  APP_MENU_ITEM_KEY,
  filterMenuItemByAuth,
  getActiveApp,
  getNavigationItems,
} from '@signifyd/components/src/thirdGenComponents/NavigationBar/navigation.utils'
import { NavBarMenuItemWithAuth } from '@signifyd/components/src/thirdGenComponents/NavigationBar/NavigationBar.types'
import { USER_ROLE } from '@signifyd/http'
import ExternalMenuLink from './ExternalMenuLink/ExternalMenuLink'

interface Props {
  teamId?: number
}

const getOverrides = (teamId?: number): Array<NavBarMenuItemWithAuth> => {
  const teamIdQueryParam = teamId ? `?teamId=${teamId}` : ''
  const { t } = i18nInstance
  const activeItem = getActiveApp(window.location)

  const rulesPageActive = activeItem === APP_MENU_ITEM_KEY.DecisionCenterRules
  const listsPageActive = activeItem === APP_MENU_ITEM_KEY.DecisionCenterLists

  return [
    {
      key: APP_MENU_ITEM_KEY.DecisionCenterRules,
      label: (
        <ExternalMenuLink
          url={`/policies/dashboard${teamIdQueryParam}`}
          pageIsActive={rulesPageActive}
          linkText={t('common.routes.policies')}
        />
      ),
      icon: (
        <SlidersDuoTone
          fill={rulesPageActive ? colorCerulean : colorAtlantic}
        />
      ),
      auth: (_user, userRolesSet) =>
        userRolesSet.has(USER_ROLE.RULES_BUILDER) ||
        userRolesSet.has(USER_ROLE.ADMIN) ||
        userRolesSet.has(USER_ROLE.SUPER_ADMIN),
    },
    {
      key: APP_MENU_ITEM_KEY.DecisionCenterLists,
      label: (
        <ExternalMenuLink
          pageIsActive={listsPageActive}
          url={`/lists${teamIdQueryParam}`}
          linkText={t('common.routes.lists')}
        />
      ),
      icon: (
        <SquareListDuoTone
          fill={listsPageActive ? colorCerulean : colorAtlantic}
        />
      ),
      auth: (_user, userRolesSet) =>
        userRolesSet.has(USER_ROLE.DECISION_CENTER_LISTS) ||
        userRolesSet.has(USER_ROLE.ADMIN) ||
        userRolesSet.has(USER_ROLE.SUPER_ADMIN),
    },
  ]
}

const ThirdGenNavBar: FC<Props> = ({ teamId }) => {
  const { user, userConfigs } = useUserContext()
  const { mutate: updateUserState } = useUpdateUserState(user)

  const updateUiState = (uiStateToUpdate: any): void => {
    const initialUiState = user.uiState ?? {}

    updateUserState({
      ...initialUiState,
      ...uiStateToUpdate,
    })
  }

  if (!user || !userConfigs) {
    return null
  }

  const userRolesSet = new Set(user.roles)

  const navigationItems = getNavigationItems()
  const itemsWithOverrides = navigationItems.map((item) => {
    if (item.key === APP_MENU_ITEM_KEY.DecisionCenter) {
      return {
        ...item,
        children: getOverrides(teamId),
      }
    }

    return item
  })

  const items = filterMenuItemByAuth(itemsWithOverrides, userRolesSet, user)

  return (
    <NavigationBar
      items={items}
      user={user}
      userConfigs={userConfigs}
      onLanguageSelectionChange={(selectedLocale) => {
        updateUiState({ locale: selectedLocale })
      }}
    />
  )
}

export default ThirdGenNavBar
