import { useUserContext } from '@signifyd/components'
import moment from 'moment-timezone'

const useUserTimeZone = (): string => {
  const { user } = useUserContext()

  const userTimeZone = user?.uiState?.timeZone?.name || moment.tz.guess()

  return userTimeZone
}

export default useUserTimeZone
