import { FC } from 'react'
import { Button, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { spacingXS } from '@signifyd/ant'
import styles from './MultiStageModalFooterThirdGen.less'

interface Props {
  onOk: () => void
  onCancel: () => void
  onPrevious: () => void
  hasPreviousState: boolean
  canContinue: boolean
  loading: boolean
}

/**
 * Renders buttons to allow a user to navigate a multi stage modal (next / ok, back, cancel)
 */
const MultiStageModalFooterThirdGen: FC<Props> = ({
  hasPreviousState,
  onPrevious,
  onCancel,
  onOk,
  canContinue,
  loading,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'common.multiStageModalFooter',
  })

  return (
    <Flex justify="space-between" className={styles.container}>
      <Button
        type="link"
        onClick={hasPreviousState ? onPrevious : onCancel}
        data-test-id={`multiStageModalFooter${
          hasPreviousState ? 'Previous' : 'Cancel'
        }Button`}
      >
        {hasPreviousState ? t('previous') : t('cancel')}
      </Button>

      <Flex gap={spacingXS}>
        {hasPreviousState && (
          <Button
            onClick={onCancel}
            type="link"
            data-test-id="multiStageModalFooterCancelButton"
          >
            {t('cancel')}
          </Button>
        )}
        <Button
          onClick={onOk}
          type="primary"
          loading={loading}
          disabled={!canContinue}
          data-test-id="multiStageModalFooterOkButton"
        >
          {t('ok')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default MultiStageModalFooterThirdGen
