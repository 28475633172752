import { useUserContext } from '@signifyd/components'
import { USER_ROLE, UserTeam } from '@signifyd/http'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'

interface UserPermissions {
  isAdmin: boolean
  isShadowing: boolean
  isSuperAdmin: boolean
  isTeamMember: boolean
  selectedTeamId?: number | null
  userCanEdit: boolean
}

const isUserTeamMember = (
  teams: Array<UserTeam>,
  id?: number | null
): boolean => teams.some(({ teamId }) => teamId === id)

const useUserPermissions = (teamId?: number): UserPermissions => {
  const { user, teams = [] } = useUserContext()
  const [{ teamId: teamIdParam }] = useDashboardPageFilters()

  const isAdmin = user?.isAdmin
  const isSuperAdmin = user?.roles?.includes(USER_ROLE.SUPER_ADMIN) ?? false

  const selectedTeamId = teamId ?? teamIdParam
  const isTeamMember = isUserTeamMember(teams, selectedTeamId)

  const isShadowing = !!(user?.shadowEmail && user?.shadowId)
  const userCanEdit = isTeamMember || isSuperAdmin

  return {
    isAdmin,
    isShadowing,
    isSuperAdmin,
    isTeamMember,
    selectedTeamId,
    userCanEdit,
  }
}

export default useUserPermissions
