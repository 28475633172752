import { FC } from 'react'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from 'use-query-params'
import { SIMULATION_STATUS } from '@signifyd/http'
import PublishedRuleSetInfo from 'core/containers/PublishedRuleSetInfo'
import { usePublishedRuleset } from 'core/hooks/usePublishedRuleSet/usePublishedRuleSet'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import usePublishRuleSet from 'core/queries/usePublishRuleSet/usePublishRuleSet'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { useStoreActions, useStoreState } from 'stores'

import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './HeaderExtra.less'
import PublishButtonThirdGen from './PublishButtonThirdGen'
import CancelButtonThirdGen from './CancelButtonThirdGen'

const RULE_SET_WRAPPER_STYLE = {
  color: '#fff',
}
const HeaderExtraThirdGen: FC = () => {
  const navigate = useNavigate()
  const { teamId, checkpoint } = useTypedRouteParams()
  const userTimeZone = useUserTimeZone()
  const [{ editedPolicyId, ruleSetId }] = useQueryParams(PublishPageQueryParams)

  const simulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )

  const { data: ruleSet } = useGetRuleSet(ruleSetId)

  const { data: publishedRuleSet } = usePublishedRuleset({
    teamId,
    checkpoint,
  })

  const publishRuleSet = usePublishRuleSet(editedPolicyId)

  const { cancelRuleSimulation } = useStoreActions(
    (actions) => actions.simulationModel
  )

  const handleConfirmCancelSimulation = (): void => {
    const cancelSimulationPromise =
      simulation?.status === SIMULATION_STATUS.RUNNING
        ? cancelRuleSimulation(simulation.simulationId)
        : Promise.resolve()

    return cancelSimulationPromise.then(() => {
      message.destroy()

      navigate(`/policies/dashboard?teamId=${teamId}`)
    })
  }

  const handleClickPublishButton = (): void => {
    if (!ruleSet?.ruleSetId || !teamId) {
      return
    }

    publishRuleSet.mutate({ ruleSet })
  }

  if (!teamId) {
    return null
  }

  return (
    <div className={styles.extra}>
      {publishedRuleSet && (
        <PublishedRuleSetInfo
          ruleSet={publishedRuleSet}
          userTimeZone={userTimeZone}
          style={RULE_SET_WRAPPER_STYLE}
        />
      )}
      <CancelButtonThirdGen
        isEditing={!!editedPolicyId}
        onClick={handleConfirmCancelSimulation}
      />
      <PublishButtonThirdGen
        onClick={handleClickPublishButton}
        publishRuleSetLoading={publishRuleSet.isLoading}
        publishRuleSetError={publishRuleSet.error}
        ruleSet={ruleSet}
      />
    </div>
  )
}

export default HeaderExtraThirdGen
