import { FC, useRef, ReactNode, useState } from 'react'
import { Flex, Table } from 'antd'
import { Space, useIsThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { GET_LIST_ENTRIES_LIMIT } from 'core/constants'
import useGetPredictionListEntries from 'core/queries/predictionListEntries/useGetPredictionListEntries'
import { useDeletePredictionListEntries } from 'core/queries/predictionListEntries/useDeletePredictionListEntries'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import { getStoreFiltersFromSearchParams } from 'pages/ListDetailsPage/ListDetailsPage.utils'
import useUserPermissions from 'core/hooks/useUserPermissions'
import useListEntriesColumnConfig from './useListEntriesColumnConfig'
import useScrollingTableBody from './useScrollingTableBody'
import ListEntriesTableHeader from './ListEntriesTableHeader'
import useSetQueryOnTableChange from './useSearchParamsOnTableChange'
import EmptyTableText from './EmptyTableText'
import styles from './ListEntriesTable.less'
import ListEntriesActions from './ListEntriesActions'

export interface ListEntriesTableProps {
  hasAnyPredictionListEntries: boolean
  predictionList: PredictionList
}

const ListEntriesTable: FC<ListEntriesTableProps> = ({
  hasAnyPredictionListEntries,
  predictionList,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const tableElm = useRef<HTMLDivElement>(null)
  const tableScrollY = useScrollingTableBody(tableElm)
  const [selectedEntryIds, setSelectedEntryIds] = useState<Array<number>>([])
  const { id: predictionListId, type: predictionListType } = predictionList

  const [query] = useListDetailsPageQueryParams()
  const filters = getStoreFiltersFromSearchParams(query)

  const {
    isLoading,
    data: { predictionListEntriesData, predictionListEntriesMeta } = {},
  } = useGetPredictionListEntries(predictionListId, filters)
  const { userCanEdit } = useUserPermissions()
  const deletePredictionListEntries = useDeletePredictionListEntries()

  const showTotalNumber = (
    total: number,
    [start, end]: [number, number]
  ): ReactNode => (
    <span>
      {t('listDetailsPage.listEntriesTable.pagination.totalNumber', {
        start,
        end,
        total,
      })}
    </span>
  )

  const columnConfig = useListEntriesColumnConfig(
    predictionListId,
    predictionListType,
    userCanEdit
  )

  const onTableChange = useSetQueryOnTableChange()

  return (
    <Flex vertical>
      <Space size="md" />
      <ListEntriesTableHeader
        predictionListType={predictionListType}
        filters={filters}
        selectedEntryIds={selectedEntryIds}
        onDeleteSelectedEntries={() =>
          deletePredictionListEntries.mutate({
            predictionListId,
            ids: selectedEntryIds,
          })
        }
        userCanEdit={userCanEdit}
      />
      {isThirdGen && (
        <>
          <ListEntriesActions
            predictionList={predictionList}
            userCanEdit={userCanEdit}
          />
          <Space size="lg" />
        </>
      )}
      <div ref={tableElm} />
      <Table
        data-test-id="listEntriesTable"
        rowKey="id"
        size={isThirdGen ? undefined : 'middle'}
        loading={isLoading}
        locale={{
          emptyText: <EmptyTableText loading={isLoading} />,
        }}
        pagination={{
          position: ['bottomCenter'],
          total: predictionListEntriesMeta?.totalRows,
          showTotal: showTotalNumber,
          current: filters?.page ? filters.page + 1 : 1,
          pageSize: filters?.pageSize || GET_LIST_ENTRIES_LIMIT,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          className: styles.pagination,
        }}
        rowSelection={
          userCanEdit
            ? {
                selectedRowKeys: selectedEntryIds,
                onChange: (ids) => {
                  setSelectedEntryIds(ids as Array<number>)
                },
              }
            : undefined
        }
        scroll={{ y: tableScrollY }}
        onChange={onTableChange}
        columns={columnConfig}
        dataSource={predictionListEntriesData}
        className={!hasAnyPredictionListEntries ? styles.emptyTable : undefined}
      />
    </Flex>
  )
}

export default ListEntriesTable
