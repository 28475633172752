import { FC } from 'react'
import { Col, Divider, Flex, Row, Select, Tooltip } from 'antd'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { TextThirdGen } from '@signifyd/components'
import { RuleResponse, Variable } from '@signifyd/http'
import GroupFilterSelect from 'core/components/GroupFilterSelect'
import useListVariableGroups from 'core/hooks/useListVariableGroups/useListVariableGroups'
import { GroupListItem } from 'core/components/GroupFilterSelect/GroupFilterSelect'
import ToggleBtn from 'core/components/ToggleBtn'
import {
  ComparisonOperator,
  SubConditionNode,
  ConditionNode,
  LOGICAL_OPERATOR,
} from 'pages/PolicyConditionsPage/components/ConditionTreeRule/ConditionTreeRule.types'
import LogicalOperatorSelect from 'pages/PolicyConditionsPage/components/LogicalOperatorSelect'
import {
  COMPARISON_OPERATOR,
  ConditionTreeLeafNodeValue,
  MIN_VALID_PARTIAL_SUBCONDITIONS,
  RuleFeaturesByName,
} from 'stores/conditionTree'
import { useSubConditionTreeActions } from 'stores/conditionTree/ConditionTreeStore'
import { spacingMD } from '@signifyd/ant'
import styles from './ConditionTreeRuleThirdGen.less'
import ConditionOperator, { stringToOperator } from './ConditionOperator'
import ConditionInput from '../ConditionInputs/ConditionElement'

interface Props {
  policyFeatureVariables: Array<Variable>
  policyFeaturesWithPredictionList: RuleFeaturesByName
  parentNode?: ConditionNode
  policy: RuleResponse
}

const ConditionTreeRuleThirdGen: FC<Props> = ({
  policyFeatureVariables,
  policyFeaturesWithPredictionList,
  parentNode,
  policy,
}) => {
  const { t } = useTranslation()

  const { data: filterVariableGroups } = useListVariableGroups(
    policyFeatureVariables
  )

  const {
    updateSubCondition,
    addSubCondition,
    removeSubCondition,
    updateSubConditionOperator,
  } = useSubConditionTreeActions()

  if (!parentNode) {
    return null
  }

  const leafNodes = parentNode.children

  const onChange = (feature: GroupListItem | null, id: string): void => {
    updateSubCondition(parentNode.id, id, {
      id,
      value: null,
      ruleFeature: policyFeaturesWithPredictionList.get(feature?.name ?? ''),
      operator: null,
      isNot: false,
    })
  }

  const onSelectOperatorChange = (
    valueOption: ComparisonOperator,
    id: string
  ): void => {
    if (!valueOption) {
      updateSubCondition(parentNode.id, id, {
        operator: null,
        value: null,
        isNot: false,
      })

      return
    }

    const { name, isNot } = stringToOperator(valueOption)

    updateSubCondition(parentNode.id, id, {
      operator: name,
      value: null,
      isNot: !!isNot,
    })
  }

  const onValueChange = (
    value: ConditionTreeLeafNodeValue,
    id: string
  ): void => {
    updateSubCondition(parentNode.id, id, {
      value,
    })
  }

  const getGroupFilterItem = (
    leafNode: SubConditionNode
  ): GroupListItem | null => {
    if (!leafNode.ruleFeature) {
      return null
    }

    return {
      id: leafNode.ruleFeature?.id,
      description: leafNode.ruleFeature?.description,
      name: leafNode.ruleFeature?.name,
    }
  }

  const subConditions = Array.from(leafNodes.values())

  return (
    <Flex gap={spacingMD} vertical>
      <LogicalOperatorSelect
        value={parentNode.operator ?? LOGICAL_OPERATOR.and}
        onSelect={(operator) =>
          updateSubConditionOperator(parentNode.id, operator)
        }
        inner
      />

      <Divider className={styles.divider} />

      {subConditions.map((leafNode) => {
        return (
          <Flex vertical key={leafNode.id}>
            <Flex gap={spacingMD} justify="space-between">
              <Row
                gutter={12}
                align="middle"
                justify="space-between"
                className={styles.inputRow}
              >
                <Col span={24} xxl={10}>
                  <GroupFilterSelect
                    data={filterVariableGroups}
                    defaultTab={filterVariableGroups.allVariables.name}
                    value={getGroupFilterItem(leafNode)}
                    onChange={(feature) => onChange(feature, leafNode.id)}
                  />
                </Col>
                <Col span={24} xxl={7}>
                  {leafNode.ruleFeature?.frontEndType && (
                    <ConditionOperator
                      featureType={leafNode.ruleFeature?.frontEndType}
                      onSelectOperator={(value: string) =>
                        onSelectOperatorChange(
                          value as COMPARISON_OPERATOR,
                          leafNode.id
                        )
                      }
                      leafNode={leafNode}
                      isPredictionListFeature={
                        !!leafNode.ruleFeature.predictionListFeature
                      }
                    />
                  )}
                </Col>
                <Col span={24} xxl={7}>
                  {leafNode?.operator && leafNode.ruleFeature?.frontEndType && (
                    <ConditionInput
                      onChangeValue={(value: ConditionTreeLeafNodeValue) =>
                        onValueChange(value, leafNode.id)
                      }
                      featureType={leafNode.ruleFeature?.frontEndType}
                      operator={leafNode.operator}
                      value={leafNode.value?.toString()}
                      leafNode={leafNode}
                      ruleTeamId={policy.teamId}
                    />
                  )}
                </Col>
              </Row>
              {(subConditions.length > 1 ||
                leafNode.ruleFeature?.frontEndType) && (
                <Tooltip
                  title={t(
                    'ruleConditionsPage.conditionInputs.deleteButton.tooltipTitle'
                  )}
                >
                  <DeleteOutlined
                    type="delete"
                    className={styles.deleteButton}
                    onClick={() =>
                      removeSubCondition(parentNode.id, leafNode.id)
                    }
                    data-test-id="subConditionDeleteButton"
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
        )
      })}

      <ToggleBtn
        onClick={() =>
          addSubCondition(
            parentNode.id,
            parentNode.operator ?? LOGICAL_OPERATOR.and
          )
        }
      >
        {t('ruleConditionsPage.conditions.addSubcondition')}
      </ToggleBtn>

      {parentNode.operator === LOGICAL_OPERATOR.numberOfConditions && (
        <>
          <Divider className={styles.divider} />
          <Row align="middle">
            <Flex gap={spacingMD} align="center">
              <Tooltip
                title={
                  subConditions.length < MIN_VALID_PARTIAL_SUBCONDITIONS
                    ? t(
                        'ruleConditionsPage.conditions.invalidNumberOfConditions'
                      )
                    : undefined
                }
                placement="topLeft"
              >
                <Select
                  onSelect={(value) =>
                    updateSubConditionOperator(
                      parentNode.id,
                      parentNode.operator ??
                        LOGICAL_OPERATOR.numberOfConditions,
                      value
                    )
                  }
                  data-test-id="numberOfConditionsSelect"
                  className={styles.numberOfConditionsSelect}
                  value={parentNode.numberOfConditions}
                  placeholder={t('ruleConditionsPage.conditions.selectNumber')}
                  disabled={
                    subConditions.length < MIN_VALID_PARTIAL_SUBCONDITIONS
                  }
                  options={Array.from(
                    { length: subConditions.length - 1 },
                    (_, index) => ({
                      key: nanoid(),
                      label: index + 1,
                      value: index + 1,
                      'data-test-id': `numberOfConditionsSelectValue-${
                        index + 1
                      }`,
                    })
                  )}
                />
              </Tooltip>
              <TextThirdGen size="sm">
                {t('ruleConditionsPage.conditions.numberOfSubConditions')}
              </TextThirdGen>
            </Flex>
          </Row>
        </>
      )}
    </Flex>
  )
}

export default ConditionTreeRuleThirdGen
