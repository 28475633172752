import { FC, useState, useEffect } from 'react'
import { Modal, Select, Row, Col, Form } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useTranslation } from 'react-i18next'
import { RuleResponse } from '@signifyd/http'
import moment, { Moment } from 'moment-timezone'
import { DatePicker, useIsThirdGen } from '@signifyd/components'
import { ButtonProps } from 'antd/es/button'
import { DATE_TIME_FORMAT } from 'core/constants'
import styles from './RuleScheduleModal.less'

interface SubmitProps {
  scheduleStart: string | null
  scheduleEnd: string | null
}
export interface RuleScheduleProps {
  loading?: boolean
  userTimeZone: string
  rule: RuleResponse | null
  title: string
  visible: boolean
  onCancel: () => void
  onSubmit: (props: SubmitProps) => void
  // Allows us to disable in tests
  virtual?: boolean
}

const { Item } = Form

const TIMEPICKER_FORMAT = 'HH:mm'

const TIMEZONES = moment.tz.names().map((name) => ({
  name,
  lowerCaseName: name.toLowerCase(),
}))
const SCHEDULE_START_TIME_PICKER_CONFIG = {
  defaultValue: moment('00:00', TIMEPICKER_FORMAT),
  format: TIMEPICKER_FORMAT,
}
const SCHEDULE_END_TIME_PICKER_CONFIG = {
  defaultValue: moment('23:59', TIMEPICKER_FORMAT),
  format: TIMEPICKER_FORMAT,
}

const filterOption = (
  inputValue: string,
  option?: DefaultOptionType
): boolean => {
  if (typeof option?.key !== 'string') {
    return false
  }

  const { key: lowerCaseName } = option

  return lowerCaseName.indexOf(inputValue.toLowerCase()) >= 0
}

const getUTCTime = (date: Moment, timeZone: string): string => {
  return moment.tz(date, timeZone).utc().format('YYYY-MM-DD[T]HH:mm:ss.[000Z]')
}

const RuleScheduleModal: FC<RuleScheduleProps> = ({
  loading,
  userTimeZone,
  title,
  rule,
  visible,
  onCancel,
  onSubmit,
  virtual = true,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const [timeZone, setTimeZone] = useState(userTimeZone)
  const [scheduleStart, setScheduleStart] = useState<Moment | null>(null)
  const [scheduleEnd, setScheduleEnd] = useState<Moment | null>(null)

  useEffect(() => {
    const schedule = rule?.scheduleStart
      ? moment.tz(rule.scheduleStart, timeZone)
      : null

    setScheduleStart(schedule)
  }, [rule, setScheduleStart, timeZone])

  useEffect(() => {
    const schedule = rule?.scheduleEnd
      ? moment.tz(rule.scheduleEnd, timeZone)
      : null

    setScheduleEnd(schedule)
  }, [rule, setScheduleEnd, timeZone])

  useEffect(() => {
    setTimeZone(timeZone)
  }, [timeZone, setTimeZone])

  if (!rule) {
    return null
  }

  return (
    <Modal
      open={visible}
      title={title}
      closable={false}
      okText={t('ruleCommon.ruleScheduleModal.okText')}
      okButtonProps={
        {
          type: isThirdGen ? 'primary' : 'link',
          disabled: false,
          loading,
          'data-analytics-id': 'rule-schedule-ok-button',
        } as ButtonProps
      }
      cancelButtonProps={
        {
          type: 'link',
          'data-analytics-id': 'rule-schedule-cancel-button',
        } as ButtonProps
      }
      cancelText={t('ruleCommon.ruleScheduleModal.cancelText')}
      onCancel={onCancel}
      onOk={() => {
        onSubmit({
          scheduleStart: scheduleStart && getUTCTime(scheduleStart, timeZone),
          scheduleEnd: scheduleEnd && getUTCTime(scheduleEnd, timeZone),
        })
      }}
    >
      <Form colon={false} data-test-id="rule-schedule-form" layout="vertical">
        <Item
          label={t('ruleCommon.ruleScheduleModal.formLabel')}
          className={styles.dateRange}
        >
          <Row gutter={16}>
            <Col span={12}>
              <DatePicker
                className={styles.datePicker}
                data-test-id="ruleScheduleStart"
                popupClassName="ruleScheduleStart"
                data-analytics-id="rule-schedule-start"
                disabledDate={(current) =>
                  current && scheduleEnd
                    ? current.isSameOrAfter(scheduleEnd)
                    : false
                }
                showTime={SCHEDULE_START_TIME_PICKER_CONFIG}
                format={DATE_TIME_FORMAT}
                value={scheduleStart || undefined}
                placeholder={t(
                  'ruleCommon.ruleScheduleModal.startInputPlaceholder'
                )}
                onChange={(date) => {
                  const dateWithTimeZone = date
                    ? moment.tz(date, timeZone)
                    : date

                  setScheduleStart(dateWithTimeZone)
                }}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                className={styles.datePicker}
                data-test-id="ruleScheduleEnd"
                popupClassName="ruleScheduleEnd"
                data-analytics-id="rule-schedule-end"
                disabledDate={(current) => {
                  if (!current) {
                    return false
                  }
                  if (current.isSameOrBefore(new Date())) {
                    return true
                  }

                  return current.isSameOrBefore(scheduleStart || undefined)
                }}
                showTime={SCHEDULE_END_TIME_PICKER_CONFIG}
                format={DATE_TIME_FORMAT}
                value={scheduleEnd || undefined}
                placeholder={t(
                  'ruleCommon.ruleScheduleModal.endInputPlaceholder'
                )}
                onChange={(date) => {
                  const dateWithTimeZone = date
                    ? moment.tz(date, timeZone)
                    : date

                  setScheduleEnd(dateWithTimeZone)
                }}
              />
            </Col>
          </Row>
        </Item>
        <Select
          showSearch
          filterOption={filterOption}
          getPopupContainer={(node) => node.parentNode as HTMLElement}
          value={timeZone}
          onChange={setTimeZone}
          data-test-id="ruleScheduleTimezone"
          virtual={virtual}
          data-analytics-id="rule-schedule-timezone"
          options={TIMEZONES.map(({ name, lowerCaseName }) => ({
            key: lowerCaseName,
            label: name,
            value: name,
          }))}
        />
      </Form>
    </Modal>
  )
}

export default RuleScheduleModal
