import { Space, TextThirdGen } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { Col, Row } from 'antd'
import { CHECKPOINT } from '@signifyd/http'
import CheckpointActionButton from 'pages/DashboardPage/components/CheckpointActionButton'
import { spacingLG, spacingMD } from '@signifyd/ant'
import useFilteredCheckpointIconData from './useFilteredCheckpointIconData'

interface Props {
  selectedCheckpoint: CHECKPOINT
  onSelectCheckpoint: (checkpoint: CHECKPOINT) => void
}

const PolicyCheckpointFormThirdGen: FC<Props> = ({
  selectedCheckpoint,
  onSelectCheckpoint,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ruleDashboardPage.policyCheckpointForm.main',
  })

  const checkpointIconData = useFilteredCheckpointIconData()

  return (
    <div data-test-id="policyCheckpointForm">
      <TextThirdGen paragraph>{t('header')}</TextThirdGen>
      <Space size={spacingMD} />
      <TextThirdGen paragraph weight="semibold">
        {t('description')}
      </TextThirdGen>
      <Space size={spacingMD} />
      <Row gutter={[spacingLG, spacingLG]}>
        {checkpointIconData.map(({ checkpoint, Icon: icon, title }) => (
          <Col key={checkpoint} xs={{ span: 24 }} lg={{ span: 8 }}>
            <CheckpointActionButton
              title={title}
              Icon={icon}
              onClick={() => onSelectCheckpoint(checkpoint)}
              selected={checkpoint === selectedCheckpoint}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default PolicyCheckpointFormThirdGen
