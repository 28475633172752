import { FC } from 'react'
import cx from 'classnames'
import { ExternalLinkThirdGen, TextThirdGen } from '@signifyd/components'

import styles from './ExternalMenuLink.less'

interface Props {
  pageIsActive: boolean
  url: string
  linkText: string
}

const ExternalMenuLink: FC<Props> = ({ pageIsActive, url, linkText }) => {
  return (
    <ExternalLinkThirdGen
      target="_self"
      onClick={(e) => e.stopPropagation()}
      url={url}
    >
      <TextThirdGen
        weight={pageIsActive ? 'semibold' : 'normal'}
        className={cx([styles.link, pageIsActive && styles.activated])}
      >
        {linkText}
      </TextThirdGen>
    </ExternalLinkThirdGen>
  )
}

export default ExternalMenuLink
