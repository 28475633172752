import { FC } from 'react'
import { Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { spacingLG } from '@signifyd/ant'
import { PredictionList } from '@signifyd/http'
import DownloadListEntriesButton from '../DownloadListEntriesButton'
import ReplaceListEntriesButtonWithModal from '../ReplaceListEntriesButtonWithModal'

export interface ListEntriesTableProps {
  predictionList: PredictionList
  userCanEdit: boolean
}

const ListEntriesActions: FC<ListEntriesTableProps> = ({
  predictionList,
  userCanEdit,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'listDetailsPage' })
  const { size, id } = predictionList

  return (
    <Flex justify="space-between" align="center">
      <TextThirdGen size="sm">
        {t('totalListPageItems', {
          count: size,
        })}
      </TextThirdGen>
      <Flex gap={spacingLG}>
        <DownloadListEntriesButton
          disabled={size === 0}
          predictionListId={id}
        />
        {userCanEdit && (
          <ReplaceListEntriesButtonWithModal
            disabled={size === 0}
            predictionList={predictionList}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default ListEntriesActions
