import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Layout as SigLayout, useIsThirdGen } from '@signifyd/components'
import { Flex, Layout } from 'antd'
import AppNavBar, { ThirdGenNavBar } from 'core/containers/AppNavBar'
import useUserPermissions from 'core/hooks/useUserPermissions'
import { UnauthorizedLayout } from 'core/components/UnauthorizedLayout/UnauthorizedLayout'
import TeamSelect from 'core/components/TeamSelect'
import { useNavigate } from 'react-router-dom'
import styles from './NoTeamsLayout.less'

const { NavBar, Content: SigContent } = SigLayout
const { Content } = Layout

export enum DESTINATION {
  POLICIES = 'POLICIES',
  LISTS = 'LISTS',
}

interface Props {
  destination: DESTINATION
}

const NoTeamsLayout: FC<Props> = ({ destination }) => {
  const { t } = useTranslation()
  const { isAdmin, isSuperAdmin } = useUserPermissions()
  const isThirdGen = useIsThirdGen()
  const navigate = useNavigate()

  if (!isAdmin && !isSuperAdmin) {
    return <UnauthorizedLayout message={t('common.unauthorized.default')} />
  }

  const title =
    destination === DESTINATION.POLICIES
      ? t('ruleCommon.appName')
      : t('listCommon.appName')
  const message = t('common.noTeamsMessage')

  const handleChange = (teamId?: string): void => {
    if (teamId) {
      const url =
        destination === DESTINATION.POLICIES ? '/policies/dashboard' : '/lists'
      navigate(`${url}?teamId=${teamId}`)
    }
  }

  const body = (
    <>
      <Flex justify="space-between" className={styles.header}>
        <h2>{title}</h2>
        <TeamSelect
          className={styles.headerTeamSelect}
          label={t('common.teamSelect.label')}
          selectedTeamId={''}
          onChange={handleChange}
        />
      </Flex>
      <Flex justify="center" align="center" className={styles.prompt}>
        <h2>{message}</h2>
      </Flex>
    </>
  )

  if (isThirdGen) {
    return (
      <Layout className={styles.layout}>
        <ThirdGenNavBar />
        <Content>{body}</Content>
      </Layout>
    )
  }

  return (
    <SigLayout>
      <NavBar>
        <AppNavBar />
      </NavBar>
      <SigContent innerStyle={{ padding: 0, height: '80vh' }}>
        {body}
      </SigContent>
    </SigLayout>
  )
}

export default NoTeamsLayout
