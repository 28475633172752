import { FC, useRef, useEffect, MutableRefObject } from 'react'
import { Button, Col, Row } from 'antd'
import { Draggable } from 'react-beautiful-dnd'
import { Trans } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import {
  Text,
  CheckpointActionTag,
  useIsThirdGen,
  CheckpointActionTagThirdGen,
} from '@signifyd/components'
import { RuleResponse, RULE_STATUS } from '@signifyd/http'
import { joinClassNames } from '@signifyd/utils'
import { checkpointActionIconMap } from 'core/constants'
import RuleName from 'core/containers/RuleName'
import RuleSchedule from 'core/containers/RuleSchedule'
import DraggableIcon from 'core/components/DraggableIcon'
import RuleStatusBadge from 'core/components/RuleStatusBadge'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { SPLIT_SCREEN_STEP } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import RuleNameThirdGen from 'core/containers/RuleName/thirdGen/RuleNameThirdGen'
import RuleSetDnDDroppableRow from './RuleSetDnDDroppableRow'
import styles from './RuleSetDnDDraggableRule.less'

const scrollToRef = (ref: MutableRefObject<any>): void => {
  if (ref.current) {
    window.scrollTo(0, ref.current.offsetTop)
  }
}

interface Props {
  rule: RuleResponse
  isPublishedRule: boolean
  selected: boolean
  index: number
  onMoveToAnotherList: () => void
  onClickSchedule?: () => void
}

const RuleSetDnDDraggableRule: FC<Props> = ({
  rule,
  index,
  isPublishedRule,
  selected,
  onMoveToAnotherList,
  onClickSchedule,
}) => {
  const [filters] = useQueryParams(PublishPageQueryParams)
  const { step } = filters
  const isThirdGen = useIsThirdGen()

  const ruleNameRef = useRef(null)

  useEffect(() => {
    if (selected) {
      scrollToRef(ruleNameRef)
    }
  }, [selected])

  const columnWrapper =
    step === SPLIT_SCREEN_STEP.STEP_ONE ? (
      <>
        <Col span={7}>
          <div className={styles.ruleName} ref={ruleNameRef}>
            {isThirdGen ? (
              <RuleNameThirdGen rule={rule} />
            ) : (
              <RuleName rule={rule} />
            )}
          </div>
        </Col>
        <Col span={3}>
          {isThirdGen ? (
            <CheckpointActionTagThirdGen
              action={rule.ruleOutcome.ruleRecommendedAction}
              hasTooltip
            />
          ) : (
            <CheckpointActionTag
              action={rule.ruleOutcome.ruleRecommendedAction}
              icon={checkpointActionIconMap[rule.checkpoint]}
              hasTooltip
            />
          )}
        </Col>
        <Col span={7}>
          {isPublishedRule && (
            <RuleSchedule showIcon rule={rule} onClick={onClickSchedule} />
          )}
        </Col>
        <Col span={2}>
          <RuleStatusBadge ruleStatus={rule.ruleStatus} />
        </Col>
        <Col span={isThirdGen ? 4 : 5} className={styles.moveBtnWrapper}>
          <Button
            className={styles.moveBtn}
            type="link"
            onClick={onMoveToAnotherList}
            data-test-id="moveToBtn"
          >
            {isPublishedRule ? (
              <Trans
                i18nKey="publishWithSimulatorPage.ruleSetDnDDraggableRule.moveToOthers"
                components={{
                  bold: (
                    <Text weight="semibold" className={styles.moveBtnBold} />
                  ),
                }}
              />
            ) : (
              <Trans
                i18nKey="publishWithSimulatorPage.ruleSetDnDDraggableRule.moveToPublish"
                components={{
                  bold: (
                    <Text weight="semibold" className={styles.moveBtnBold} />
                  ),
                }}
              />
            )}
          </Button>
          <DraggableIcon />
        </Col>
      </>
    ) : (
      <>
        <div className={styles.ruleName} ref={ruleNameRef}>
          {isThirdGen ? (
            <RuleNameThirdGen rule={rule} />
          ) : (
            <RuleName rule={rule} />
          )}
        </div>

        {isThirdGen ? (
          <CheckpointActionTagThirdGen
            action={rule.ruleOutcome.ruleRecommendedAction}
            hasTooltip
          />
        ) : (
          <CheckpointActionTag
            action={rule.ruleOutcome.ruleRecommendedAction}
            hasTooltip
          />
        )}
      </>
    )

  return (
    <Draggable draggableId={String(rule.ruleId)} index={index}>
      {(provided, snapshot) => (
        <RuleSetDnDDroppableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={joinClassNames([
            styles.ruleItem,
            snapshot.isDragging && styles.dragging,
            !isPublishedRule && styles.notPublished,
            selected && styles.selected,
            isPublishedRule &&
              rule.ruleStatus === RULE_STATUS.EXPIRED &&
              styles.expired,
          ])}
        >
          <Row
            className={styles.row}
            align="middle"
            justify="space-between"
            gutter={16}
            data-test-id="ruleSetDnDDroppableRow"
            data-analytics-id="rule-set-dnd-droppable-row"
          >
            {columnWrapper}
          </Row>
        </RuleSetDnDDroppableRow>
      )}
    </Draggable>
  )
}

export default RuleSetDnDDraggableRule
