import { FunctionComponent, ReactNode } from 'react'
import { Card, Divider, Flex, Typography } from 'antd'
import { colorSnow } from '@signifyd/colors'
import { useIsThirdGen } from '@signifyd/components'
import { spacingMD } from '@signifyd/ant'
import styles from './RuleConfigCard.less'

const bodyStyle = {
  background: colorSnow,
}

interface Props {
  label: string
  children: ReactNode
}

const RuleConfigCard: FunctionComponent<Props> = ({ label, children }) => {
  const isThirdGen = useIsThirdGen()

  if (!isThirdGen) {
    return (
      <Card size="small" styles={{ body: bodyStyle }} className={styles.card}>
        <div className={styles.body}>
          <div className={styles.label}>{label}</div>
          <div className={styles.content}>{children}</div>
        </div>
      </Card>
    )
  }

  return (
    <Card>
      <Flex gap={spacingMD} align="center">
        <Typography.Title level={4} className={styles.title}>
          {label}
        </Typography.Title>
        <Divider type="vertical" className={styles.divider} />
        <div className={styles.content}>{children}</div>
      </Flex>
    </Card>
  )
}

export default RuleConfigCard
