import { FC, ReactNode, useState } from 'react'
import { Select } from 'antd'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ENTITY_TYPE,
  EntitySingleSelect,
  InputLabel,
} from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { ExperimentOutlined } from '@ant-design/icons'
import useUserTeams from 'core/hooks/useUserTeams'
import useUserPermissions from 'core/hooks/useUserPermissions'
import styles from './TeamSelect.less'

interface Props {
  className?: string
  label?: ReactNode
  selectedTeamId: string
  onChange: (teamId?: string) => void
}

const TeamSelect: FC<Props> = ({
  className,
  label,
  selectedTeamId,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { isAdmin, isSuperAdmin } = useUserPermissions()
  const { getFilteredTeams } = useUserTeams()

  // Passing searchValue into this isn't doing anything, all filtering is currently done via the Select with the optionFilterProp
  const liveTeams = getFilteredTeams(searchValue, selectedTeamId)
  const { t } = useTranslation()

  const handleSearch = (text: string): void => {
    setSearchValue(text)
  }

  // currently the isAdmin flag is only set when a user has the USER_ROLE.ADMIN role
  if (!liveTeams && !isAdmin && !isSuperAdmin) {
    return null
  }

  return (
    <div className={joinClassNames([styles.wrapper, className])}>
      {label && <InputLabel>{label}</InputLabel>}
      {isAdmin || isSuperAdmin ? (
        <div data-test-id="adminTeamSelect">
          <EntitySingleSelect
            entityType={ENTITY_TYPE.TEAM}
            selectedKey={selectedTeamId}
            onSave={onChange}
          />
        </div>
      ) : (
        <Select
          onSearch={debounce(handleSearch, 300)}
          showSearch
          data-test-id="teamSelect"
          className={styles.select}
          value={selectedTeamId}
          onChange={onChange}
          notFoundContent={t('common.teamSelect.notFoundContent')}
          optionFilterProp="text"
          options={liveTeams?.map(({ teamId, teamName, test }) => ({
            key: teamId,
            label: (
              <>
                {test && (
                  <ExperimentOutlined className={styles.teamSelectTestIcon} />
                )}
                {teamName}
              </>
            ),
            // this shouldn't be needed, but setting optionFilterProp to "label" doesn't seem to filter correctly
            text: teamName,
            value: String(teamId),
          }))}
        />
      )}
    </div>
  )
}

export default TeamSelect
