import { FC, ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { USER_ROLE, VariableFrontEndType } from '@signifyd/http'
import { Text, useUserContext } from '@signifyd/components'
import { Divider, Select } from 'antd'
import cx from 'classnames'
import {
  BIN_COMPARISON_OPERATORS,
  BOOLEAN_COMPARISON_OPERATOR,
  CATEGORICAL_COMPARISON_OPERATORS,
  COMPARISON_OPERATOR,
  ComparisonOperatorWithNot,
  EMPTY_COMPARISON_OPERATORS,
  LIST_COMPARISON_OPERATORS,
  NUMBER_COMPARISON_OPERATORS,
  OPERATOR_OPTIONS_GROUPS,
  STRING_COMPARISON_OPERATORS,
  STRING_LIST_COMPARISON_OPERATORS,
} from 'stores/conditionTree'
import getComparisonOperatorTranslation from 'core/utils/translations/getComparisonOperatorTranslation'
import { SubConditionNode } from 'pages/PolicyConditionsPage/components/ConditionTreeRule/ConditionTreeRule.types'
import styles from './ConditionTreeRule.less'

interface Props {
  featureType: VariableFrontEndType
  leafNode: SubConditionNode
  onSelectOperator: (value: string) => void
  isPredictionListFeature: boolean
}

export interface OperatorTypes {
  operators: Array<ComparisonOperatorWithNot>
  predictionListOperators?: Array<ComparisonOperatorWithNot>
}

export const operators: Record<VariableFrontEndType, OperatorTypes> = {
  bin: {
    operators: BIN_COMPARISON_OPERATORS,
    predictionListOperators: [
      ...BIN_COMPARISON_OPERATORS,
      ...LIST_COMPARISON_OPERATORS,
    ],
  },
  stringList: {
    operators: [
      ...STRING_LIST_COMPARISON_OPERATORS,
      ...EMPTY_COMPARISON_OPERATORS,
    ],
    predictionListOperators: [
      ...STRING_LIST_COMPARISON_OPERATORS,
      ...LIST_COMPARISON_OPERATORS,
      ...EMPTY_COMPARISON_OPERATORS,
    ],
  },
  string: {
    operators: [
      ...STRING_COMPARISON_OPERATORS,
      ...STRING_LIST_COMPARISON_OPERATORS,
      ...EMPTY_COMPARISON_OPERATORS,
    ],
    predictionListOperators: [
      ...STRING_COMPARISON_OPERATORS,
      ...STRING_LIST_COMPARISON_OPERATORS,
      ...LIST_COMPARISON_OPERATORS,
      ...EMPTY_COMPARISON_OPERATORS,
    ],
  },
  boolean: {
    operators: [BOOLEAN_COMPARISON_OPERATOR],
  },
  categorical: {
    operators: CATEGORICAL_COMPARISON_OPERATORS,
    predictionListOperators: [
      ...CATEGORICAL_COMPARISON_OPERATORS,
      ...LIST_COMPARISON_OPERATORS,
    ],
  },
  number: {
    operators: NUMBER_COMPARISON_OPERATORS,
    predictionListOperators: [
      ...NUMBER_COMPARISON_OPERATORS,
      ...LIST_COMPARISON_OPERATORS,
    ],
  },
}

export const operatorToString = ({
  name,
  isNot = false,
}: ComparisonOperatorWithNot): string => `${name}-${isNot}`

export const stringToOperator = (str: string): ComparisonOperatorWithNot => {
  const [name, isNot] = str.split('-') as [
    COMPARISON_OPERATOR,
    'true' | 'false',
  ]

  return {
    name,
    isNot: isNot === 'true',
  }
}

interface SelectOptions {
  value: string
  label: string
  icon?: ReactElement
  className?: string
}

type FormattedGroup = {
  label: ReactElement | false
  title: OPERATOR_OPTIONS_GROUPS
  options: Array<SelectOptions>
  className: string
}

export const formatGroup = (
  group: OPERATOR_OPTIONS_GROUPS,
  options: Array<ComparisonOperatorWithNot>,
  hasDivider = true
): FormattedGroup | undefined =>
  options?.reduce(
    (acc, operator) => {
      if (operator.optionGroup === group) {
        acc.options.push({
          value: operatorToString(operator),
          label: getComparisonOperatorTranslation(operator),
          icon: operator.icon,
          className: cx(styles.options, styles.optionPadding),
        })
      }

      return acc
    },
    {
      label: hasDivider && <Divider className={styles.divider} />,
      title: group,
      options: [] as Array<SelectOptions>,
      className: cx(
        styles.optionsGroup,
        hasDivider && styles.withDivider,
        !hasDivider && styles.withoutDivider
      ),
    }
  )

const ConditionOperator: FC<Props> = ({
  featureType,
  leafNode,
  onSelectOperator,
  isPredictionListFeature,
}) => {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const roles = user?.roles
  const predictionListEnabled =
    isPredictionListFeature && roles?.includes(USER_ROLE.DECISION_CENTER_LISTS)

  const featureTypeKey = predictionListEnabled
    ? 'predictionListOperators'
    : 'operators'

  const options = operators[featureType][featureTypeKey]

  const optGroups = Object.values(OPERATOR_OPTIONS_GROUPS)

  const selectOptions = optGroups?.reduce((formattedOptions, group) => {
    const formattedGroup = formatGroup(
      group,
      options!,
      !!formattedOptions.length
    )

    if (formattedGroup?.options.length) {
      formattedOptions.push(formattedGroup)
    }

    return formattedOptions
  }, [] as Array<FormattedGroup>)

  const fallback =
    selectOptions?.length === 1 ? selectOptions?.[0]?.options[0]?.value : null

  const value = leafNode?.operator
    ? getComparisonOperatorTranslation(
        stringToOperator(`${leafNode.operator}-${leafNode.isNot}`)
      )
    : null

  useEffect(() => {
    if (!value && fallback) {
      onSelectOperator(fallback)
    }
  }, [value, fallback, onSelectOperator])

  return (
    <Select<string>
      className={styles.input}
      data-test-id={`${featureType}OperatorSelect`}
      placeholder={t(
        'ruleConditionsPage.conditionInputs.operatorSelect.placeholder'
      )}
      listHeight={270}
      value={value ?? fallback}
      onSelect={(value) => onSelectOperator(value)}
      defaultValue={null}
      options={options ? selectOptions : []}
      optionRender={({ data: { label, icon, value } }) => (
        <div data-test-id={`${featureType}Value-${value}`}>
          {icon ?? <div className={styles.blankIcon} />}
          <Text className={styles.label}>{label}</Text>
        </div>
      )}
    />
  )
}

export default ConditionOperator
