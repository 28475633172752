import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'
import { Flex } from 'antd'
import { spacingXXS } from '@signifyd/ant'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './RuleNameThirdGen.less'

interface Props {
  rule: RuleResponse
}

const RuleNameThirdGen: FC<Props> = ({ rule }) => {
  const { t } = useTranslation()
  const userTimeZone = useUserTimeZone()

  return (
    <Flex vertical>
      <TextThirdGen weight="semibold" paragraph>
        {rule.name}
      </TextThirdGen>
      <Flex gap={spacingXXS}>
        <TextThirdGen size="sm">
          {rule.createdByName || rule.createdBy}
        </TextThirdGen>
        <Flex gap={spacingXXS}>
          <TextThirdGen size="sm" className={styles.createdAt}>
            {t('publishWithSimulatorPage.ruleName.created')}
          </TextThirdGen>
          <TextThirdGen size="sm" className={styles.createdAt}>
            <FormatDate timeZone={userTimeZone} UTCTimestamp={rule.createdAt} />
          </TextThirdGen>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RuleNameThirdGen
