import { Layout } from '@signifyd/components'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CHECKPOINT } from '@signifyd/http'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import TeamSelect from 'core/components/TeamSelect'
import useUserPermissions from 'core/hooks/useUserPermissions'
import { Flex } from 'antd'
import { spacingLG } from '@signifyd/ant'
import CreatePolicyButton from '../CreatePolicyButton'
import PublishSettingsButton from '../PublishSettingsButton/PublishSettingsButton'

const { Header } = Layout

interface Props {
  isCreatePolicyModalVisible: boolean
  selectedTeamCheckpoints: Array<CHECKPOINT>
  teamId?: number
}

const DashboardHeader: FC<Props> = ({
  isCreatePolicyModalVisible,
  selectedTeamCheckpoints,
  teamId,
}) => {
  const { t } = useTranslation()

  const [, setFilters] = useDashboardPageFilters()
  const { userCanEdit } = useUserPermissions()

  if (!teamId) {
    return <Header title={t('ruleCommon.appName')} theme="light" />
  }

  const hasCheckpointAccess = !!selectedTeamCheckpoints.length && userCanEdit

  return (
    <Header
      title={t('ruleCommon.appName')}
      theme="light"
      extra={
        <Flex gap={spacingLG}>
          <TeamSelect
            label={t('common.teamSelect.label')}
            selectedTeamId={teamId.toString()}
            onChange={(teamId) => {
              if (teamId) {
                setFilters({ teamId: +teamId })
              }
            }}
          />
          {hasCheckpointAccess && (
            <>
              <PublishSettingsButton
                teamId={teamId}
                teamCheckpoints={selectedTeamCheckpoints}
              />

              <CreatePolicyButton
                icon="plus"
                defaultModalVisible={isCreatePolicyModalVisible}
                teamCheckpoints={selectedTeamCheckpoints}
              >
                {t('ruleCommon.createRuleModal.triggerBtnText')}
              </CreatePolicyButton>
            </>
          )}
        </Flex>
      }
      marginBottom="none"
    />
  )
}

export default DashboardHeader
