import { RuleResponse } from '@signifyd/http'
import { useMemo } from 'react'
import {
  ConditionTreeNode,
  deserializePredicateWithListIdReplacement,
  isPredicateUsingPredictionListFeatures,
} from 'stores/conditionTree'
import { PolicyFeaturesByName } from 'stores/rule'
import { PredictionListMap } from 'stores/rule/rule.store.constants'

const useDisplayOnlyConditionTree = (
  policy: RuleResponse | null,
  conditionTree: ConditionTreeNode | null,
  policyFeaturesByName: PolicyFeaturesByName | null,
  predictionListMap: PredictionListMap | null
): ConditionTreeNode | null =>
  useMemo(() => {
    if (!policy) {
      return null
    }

    if (!isPredicateUsingPredictionListFeatures(policy.predicate)) {
      return conditionTree
    }

    if (policyFeaturesByName && predictionListMap) {
      const displayConditionTree = deserializePredicateWithListIdReplacement(
        JSON.parse(policy?.predicate || '{}'),
        policyFeaturesByName,
        Object.values(predictionListMap).flat()
      )

      return displayConditionTree
    }

    return null
  }, [conditionTree, policy, policyFeaturesByName, predictionListMap])

export default useDisplayOnlyConditionTree
