import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { searchTeams, BasicTeam } from '@signifyd/http'

interface UseGetTeamsParams {
  teamId?: string | null
  enabled?: boolean
}

const useGetTeams = ({
  teamId,
  enabled = true,
}: UseGetTeamsParams): UseQueryResult<Array<BasicTeam>> => {
  return useQuery({
    queryKey: ['team', teamId],
    enabled: enabled && !!teamId,
    queryFn: async () => {
      const { data } = await searchTeams({ teamIds: [teamId!] })

      return data.teams
    },
  })
}

export default useGetTeams
