import { useMemo } from 'react'
import { useUserContext } from '@signifyd/components'
import { UserTeam } from '@signifyd/http'

interface UserTeamsResult {
  getFilteredTeams: (
    search: string | undefined,
    selectedTeamId: string
  ) => Array<UserTeam> | undefined
  liveTeams?: Array<UserTeam>
}

const useUserTeams = (): UserTeamsResult => {
  const { teams } = useUserContext()

  const liveTeams = useMemo(
    () =>
      teams
        ?.filter(({ active }) => active)
        .sort((a, b) => Number(a.test) - Number(b.test)),
    [teams]
  )

  const getFilteredTeams = useMemo(
    () =>
      (search = '', selectedTeamId: string) => {
        const MAX_TEAMS = 1000

        return liveTeams
          ?.reduce((previous, current) => {
            const currentTeamId = current.teamId.toString()

            if (currentTeamId === selectedTeamId) {
              return [current, ...previous]
            }

            if (current.teamName.toLowerCase().includes(search.toLowerCase())) {
              return [...previous, current]
            }

            return previous
          }, [] as Array<UserTeam>)
          .slice(0, MAX_TEAMS)
      },
    [liveTeams]
  )

  return {
    getFilteredTeams,
    liveTeams,
  }
}

export default useUserTeams
