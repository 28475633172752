export default {
  placeholderTitle: 'Create a list to use in policy configuration',
  placeholderDescription: 'Get started by clicking <bold>Create list</bold>',
  noResultsTitle: 'No results found',
  noResultsDescription: 'Please adjust the criteria',
  sortByLabel: 'Sort by',
  sortByFilter: {
    LAST_UPDATE: 'Last update',
    LIST_NAME_A_TO_Z: 'List name A - Z',
    LIST_NAME_Z_TO_A: 'List name Z - A',
    NUMBER_OF_ITEMS: 'Number of items',
    NUMBER_OF_RULES: 'Number of policies',
  },
  typeLabel: 'Type',
  listTypeFilter: {
    ALL: 'All ({{count}})',
    EMAIL: 'Email ({{count}})',
    CARD_BIN: 'Credit card BIN ({{count}})',
    IP_ADDRESS: 'IP address ({{count}})',
    DEVICE_ID: 'Device ID ({{count}})',
    ITEM_ID: 'Item ID ({{count}})',
    DISCOUNT_CODE: 'Discount code ({{count}})',
    PHONE_NUMBER: 'Phone number ({{count}})',
    ADDRESS: 'Address ({{count}})',
    PROMO_ABUSE: 'Promo abuse ({{count}})',
    RESELLER_ABUSE: 'Reseller abuse ({{count}})',
    EMAIL_DOMAIN: 'Email domain ({{count}})',
    MEMBERSHIP_ID: 'Membership ID ({{count}})',
    ACCOUNT_NUMBER: 'Account number ({{count}})',
    TAX_ID: 'Tax ID ({{count}})',
  },
  expirationLabel: 'Expiration',
  listExpirationFilter: {
    ALL: 'All',
    YES: 'Yes',
    NO: 'No',
  },
  listCard: {
    item_one: 'Item',
    item_other: 'Items',
    rule_one: 'Policy',
    rule_other: 'Policies',
    expirationText_one: 'List has {{count}} expiration.',
    expirationText_other: 'List has {{count}} expirations.',
  },
  list: 'List',
}
