import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { Space, TextThirdGen } from '@signifyd/components'
import { Card, Divider, Flex, Typography } from 'antd'
import ToggleBtn from 'core/components/ToggleBtn'
import LogicalOperatorSelect from 'pages/PolicyConditionsPage/components/LogicalOperatorSelect'
import { useStoreState } from 'stores'
import { LOGICAL_OPERATOR, getRuleFeaturesByName } from 'stores/conditionTree'
import {
  useConditionTreeActions,
  useConditionTreeStore,
} from 'stores/conditionTree/ConditionTreeStore'
import { useSetConditionTreeFromPolicy } from 'stores/conditionTree/useGetConditionTreeFromPolicy'
import { spacingMD } from '@signifyd/ant'
import ConditionTreeRuleThirdGen from 'pages/PolicyConditionsPage/components/ConditionTreeRule/ConditionTreeRuleThirdGen'
import styles from './RuleConditionsThirdGen.less'

interface Props {
  isEditing: boolean
}

const RuleConditionsThirdGen: FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation()

  const { policy, policyFeatures } = useStoreState((state) => state.ruleModel)

  useSetConditionTreeFromPolicy(isEditing)

  const conditionTree = useConditionTreeStore((state) => state.conditionTree)

  const { removeCondition, updateConditionOperator, addCondition } =
    useConditionTreeActions()

  if (!policy || !conditionTree || !policyFeatures) {
    return null
  }

  const policyFeaturesByName = getRuleFeaturesByName(policyFeatures)

  const conditions = Array.from(conditionTree.nodes.keys())

  return (
    <>
      <TextThirdGen weight="semibold">
        {t('ruleConditionsPage.conditions.conditionTitle')}
      </TextThirdGen>

      <Space size={16} />

      <LogicalOperatorSelect
        value={conditionTree.operator ?? LOGICAL_OPERATOR.and}
        onSelect={(operator) => updateConditionOperator(operator)}
      />

      <Space size={16} />

      <Flex gap={spacingMD} vertical>
        {conditions.map((nodeId, index) => (
          <Flex
            gap={spacingMD}
            vertical
            key={nodeId}
            data-test-id={`conditionPanel-${index}`}
          >
            {index !== 0 && conditionTree?.operator && (
              <Divider orientation="left" plain>
                <TextThirdGen>
                  {t('ruleConditionsPage.conditions.conditionNumber', {
                    number: index + 1,
                  })}
                </TextThirdGen>
                <DeleteOutlined
                  type="delete"
                  className={styles.deleteButton}
                  onClick={() => removeCondition(nodeId)}
                  data-test-id={`conditionDeleteButton-${index + 1}`}
                />
              </Divider>
            )}

            <Card size="small" className={styles.card}>
              <Flex gap={spacingMD}>
                <div className={styles.label}>
                  <Typography.Title level={4} className={styles.title}>
                    {t('ruleConditionsPage.conditions.conditionLabel')}
                  </Typography.Title>
                </div>
                <div className={styles.rules}>
                  <ConditionTreeRuleThirdGen
                    policyFeatureVariables={policyFeatures}
                    policyFeaturesWithPredictionList={policyFeaturesByName}
                    parentNode={conditionTree.nodes.get(nodeId)}
                    policy={policy}
                  />
                </div>
              </Flex>
            </Card>
          </Flex>
        ))}
      </Flex>
      <Space size={16} />

      <ToggleBtn
        onClick={() =>
          addCondition(conditionTree.operator ?? LOGICAL_OPERATOR.and)
        }
      >
        <span>{t('ruleConditionsPage.conditions.addCondition')}</span>
      </ToggleBtn>

      <Space size={32} />
    </>
  )
}

export default RuleConditionsThirdGen
