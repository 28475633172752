import { FC } from 'react'
import { Breadcrumb, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { TextThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import Header from '@signifyd/components/src/components/Layout/_Header'
import { spacingMD } from '@signifyd/ant'
import useUserPermissions from 'core/hooks/useUserPermissions'
import ListDetailsPageTitleThirdGen from './ListDetailsPageTitleThirdGen'
import ListDetailsPageOrdersToggle from './ListDetailsPageOrdersToggle'
import CreateItemsButtonWithModal from '../CreateListEntriesButtonWithModal/CreateListEntriesButtonWithModal'
import DeleteListButtonWithModal from '../DeleteListButtonWithModal'

interface Props {
  predictionList: PredictionList
}

const ListDetailsPageHeaderThirdGen: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()

  const [query] = useDashboardPageFilters()
  const { userCanEdit } = useUserPermissions()

  return (
    <>
      <Header
        title={
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <TextThirdGen size="sm">{t('listPage.list')} </TextThirdGen>
                ),
                href: `/lists?teamId=${query.teamId}`,
              },
              {
                title: (
                  <TextThirdGen size="sm">{predictionList.name}</TextThirdGen>
                ),
                href: `/lists?teamId=${query.teamId}`,
              },
            ]}
          />
        }
        theme="light"
        marginBottom="none"
      />
      <Header
        title={
          <ListDetailsPageTitleThirdGen
            predictionList={predictionList}
            userCanEdit={userCanEdit}
          />
        }
        extra={
          userCanEdit && (
            <Flex gap={spacingMD}>
              <ListDetailsPageOrdersToggle predictionList={predictionList} />

              <Flex gap={spacingMD}>
                <CreateItemsButtonWithModal predictionList={predictionList} />
                <DeleteListButtonWithModal
                  predictionListId={predictionList.id}
                  disabled={predictionList.ruleIds.length > 0}
                />
              </Flex>
            </Flex>
          )
        }
        theme="light"
        marginBottom="none"
      />
    </>
  )
}

export default ListDetailsPageHeaderThirdGen
