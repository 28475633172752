import { EyeFilled } from '@ant-design/icons'
import { Trans } from 'react-i18next'
import { Text, useUserContext } from '@signifyd/components'
import { FC } from 'react'
import { Flex } from 'antd'
import useUserPermissions from 'core/hooks/useUserPermissions'
import useGetTeams from 'core/queries/useGetTeams'
import styles from './SelectedTeamBanner.less'

const SelectedTeamBanner: FC = () => {
  const { user } = useUserContext()
  const { isAdmin, isShadowing, isSuperAdmin, isTeamMember, selectedTeamId } =
    useUserPermissions()

  const isSearchEnabled =
    (isAdmin || isSuperAdmin) && !isTeamMember && !isShadowing

  const { data: teams } = useGetTeams({
    teamId: selectedTeamId?.toString(),
    enabled: isSearchEnabled,
  })

  if (isShadowing || isTeamMember || !teams?.length) {
    return null
  }

  const bannerText = isSuperAdmin
    ? 'common.selectedTeamBanner.acting'
    : 'common.selectedTeamBanner.viewing'

  return (
    <Flex justify="center">
      <div className={styles.banner}>
        <EyeFilled className={styles.bannerIcon} />
        <Trans
          shouldUnescape
          i18nKey={bannerText}
          values={{
            teamName: teams[0].teamName,
            user: user.name,
          }}
          components={{
            bold: <Text weight="semibold" />,
          }}
        />
      </div>
    </Flex>
  )
}

export default SelectedTeamBanner
