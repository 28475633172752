import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PageSpinner,
  Layout as SigLayout,
  useIsThirdGen,
} from '@signifyd/components'
import AppNavBar from 'core/containers/AppNavBar'
import { UnauthorizedLayout } from 'core/components/UnauthorizedLayout/UnauthorizedLayout'
import TestTeamBanner from 'core/components/TestTeamBanner'
import useUserPermissions from 'core/hooks/useUserPermissions/useUserPermissions'
import NoTeamsLayout, {
  DESTINATION,
} from 'core/components/NoTeamsLayout/NoTeamsLayout'
import PageWrapper from 'core/containers/PageWrapper/PageWrapper'
import DashboardContainer from './containers/DashboardContainer'
import useInitializeDashboard from './hooks/useInitializeDashboard'
import DashboardHeader from './components/DashboardHeader/DashboardHeader'
import DashboardContainerThirdGen from './containers/DashboardContainerThirdGen'

const { NavBar, Content: SigContent } = SigLayout

const DashboardPage: FC = () => {
  const { t } = useTranslation()
  const [createRuleModalVisible, setCreateRuleModalVisible] = useState(false)
  const { isLoading, selectedTeamCheckpoints, teamId } =
    useInitializeDashboard()
  const { isAdmin, isSuperAdmin } = useUserPermissions()

  const isThirdGen = useIsThirdGen()

  if (!teamId) {
    return <NoTeamsLayout destination={DESTINATION.POLICIES} />
  }

  if (isLoading) {
    return <PageSpinner />
  }

  const hasCheckpoints = selectedTeamCheckpoints?.length
  const message =
    isAdmin || isSuperAdmin
      ? t('common.unauthorized.noCheckpoints')
      : t('common.unauthorized.default')

  if (isThirdGen) {
    return (
      <PageWrapper>
        <TestTeamBanner
          teamId={teamId}
          text={t('common.testTeamBanner.policy')}
        />

        <DashboardHeader
          isCreatePolicyModalVisible={createRuleModalVisible}
          selectedTeamCheckpoints={selectedTeamCheckpoints}
          teamId={teamId}
        />

        {hasCheckpoints ? (
          <DashboardContainerThirdGen
            selectedTeamCheckpoints={selectedTeamCheckpoints}
            setCreateRuleModalVisible={setCreateRuleModalVisible}
          />
        ) : (
          <UnauthorizedLayout message={message} />
        )}
      </PageWrapper>
    )
  }

  return (
    <SigLayout>
      <NavBar>
        <AppNavBar teamId={Number(teamId)} />
      </NavBar>
      <TestTeamBanner
        teamId={teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <SigContent innerStyle={{ padding: 0 }}>
        <DashboardHeader
          isCreatePolicyModalVisible={createRuleModalVisible}
          selectedTeamCheckpoints={selectedTeamCheckpoints}
          teamId={teamId}
        />

        {hasCheckpoints ? (
          <DashboardContainer
            selectedTeamCheckpoints={selectedTeamCheckpoints}
            setCreateRuleModalVisible={setCreateRuleModalVisible}
          />
        ) : (
          <UnauthorizedLayout message={message} />
        )}
      </SigContent>
    </SigLayout>
  )
}

export default DashboardPage
