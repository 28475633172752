import { FC } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { P, T4, Text } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'
import PredictionListTypeTag from 'core/containers/PredictionListTypeTag'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './PredictionListCard.less'

interface Props {
  predictionList: PredictionList
}

const PredictionListCard: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userTimeZone = useUserTimeZone()

  const [query] = useDashboardPageFilters()
  const currentTeamId = query.teamId

  const itemText = `${predictionList.size} ${t('listPage.listCard.item', {
    count: predictionList.size,
  })}`
  const ruleText = `${predictionList.ruleIds.length} ${t(
    'listPage.listCard.rule',
    {
      count: predictionList.ruleIds.length,
    }
  )}`

  const expirationListCounter = Object.values(predictionList.expiration).filter(
    (value) => value !== null
  ).length

  const expirationListText = t('listPage.listCard.expirationText', {
    count: expirationListCounter,
  })

  const hasExpirationList = expirationListCounter > 0

  const navigationUrl = `/lists/${predictionList.id}?teamId=${currentTeamId}`

  return (
    <Card
      data-datadog-id="prediction-list-card"
      data-test-id="predictionListCard"
      hoverable
      onClick={() => {
        navigate(navigationUrl, {
          state: { backToPreviousPage: true },
        })
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <PredictionListTypeTag type={predictionList.type} />
          <Text type="secondary" size="sm">
            <FormatDate
              UTCTimestamp={predictionList.updatedAt}
              format="M/D/YYYY"
              timeZone={userTimeZone}
            />
          </Text>
        </div>
        <P ellipsis={{ rows: 2 }}>
          <T4 className={styles.listName}>{predictionList.name}</T4>
        </P>
        <P ellipsis={{ rows: 3 }} className={styles.listDescription}>
          {predictionList.description}
        </P>
        <Text type="secondary" size="sm" className={styles.itemCount}>
          {itemText}
        </Text>
        <Text type="secondary" size="sm" className={styles.ruleCount}>
          {ruleText}
        </Text>
      </div>

      {hasExpirationList && (
        <div className={styles.expirationBanner}>
          <ClockCircleOutlined className={styles.expirationIcon} />
          <Text className={styles.expirationText}>{expirationListText}</Text>
        </div>
      )}
    </Card>
  )
}

export default PredictionListCard
