import {
  FeatureFlagConfig,
  FeatureFlagController,
  useUserContext,
} from '@signifyd/components'
import { FC } from 'react'

const flagConfig: Array<FeatureFlagConfig> = [
  {
    key: 'has-third-gen-ui',
    label: 'Third Gen',
  },
  {
    key: 'has-pilot-policies',
    label: 'Pilot Policies',
  },
]

const DecisionCenterFeatureFlagController: FC = ({ children }) => {
  const { user } = useUserContext()

  return (
    <FeatureFlagController user={user} featureFlagConfig={flagConfig}>
      {children}
    </FeatureFlagController>
  )
}

export default DecisionCenterFeatureFlagController
