import { FC } from 'react'
import { Layout, useIsThirdGen } from '@signifyd/components'
import { useTranslation } from 'react-i18next'

const { Header } = Layout

interface Props {
  isEditing: boolean
}

const PolicyHeader: FC<Props> = ({ isEditing }) => {
  const isThirdGen = useIsThirdGen()
  const { t } = useTranslation()

  return (
    <Header
      productName={t('ruleCommon.appName')}
      title={t('ruleCommon.rulePageHeader.title', {
        context: isEditing ? 'edit' : 'create',
      })}
      extra={isEditing ? '' : t('ruleCommon.rulePageHeader.extra')}
      theme={isThirdGen ? 'default' : 'light'}
    />
  )
}

export default PolicyHeader
