import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import { joinClassNames } from '@signifyd/utils'
import FormatDate from 'core/components/FormatDate'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './RuleName.less'

interface Props {
  rule: RuleResponse
}

const RuleName: FC<Props> = ({ rule }) => {
  const { t } = useTranslation()
  const userTimeZone = useUserTimeZone()

  return (
    <div>
      <Text
        size="md"
        weight="semibold"
        block
        className={joinClassNames([styles.text, styles.ruleName])}
      >
        {rule.name}
      </Text>
      <Text
        size="xs"
        block
        className={`${styles.text} ${styles.createByWrapper}`}
      >
        <span className={styles.createdBy}>
          {rule.createdByName || rule.createdBy}
        </span>{' '}
        <Text type="secondary" size="xs">
          {t('publishWithSimulatorPage.ruleName.created')}{' '}
          <FormatDate timeZone={userTimeZone} UTCTimestamp={rule.createdAt} />
        </Text>
      </Text>
    </div>
  )
}

export default RuleName
